import babelPolyfill from '@babel/polyfill';
// import AOS from 'aos';
import style from './_scss/main.scss';
// import 'aos/dist/aos.css';

import sr from './js/sr';

// const colors = ["#70FF00", /*"#FF0000",*/ "#112b39"];
const colors = [
  '#5ED700',
  '#5ED700',
  '#5ED700',
  // "#d20000",
  /* "#FF0000", */

  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
  '#112b39',
];
const blobs = document.querySelectorAll('#background path');

blobs.forEach(blob => {
  blob.style.fill = colors[Math.floor(Math.random() * colors.length)];
});

// sr.reveal('.offer-item', { duration: 1000 }, 100);
sr.reveal('.offer-item' /* , { reset: true } */);
sr.reveal('.feature' /* , { reset: true } */);

function initialize() {
  const myLatlng = new google.maps.LatLng(54.551084, 18.091991);
  const mapOptions = {
    scrollwheel: false,
    center: myLatlng,
    zoom: 11,
    draggable: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
  };
  const map = new google.maps.Map(document.getElementById('mapa'), mapOptions);

  const marker = new google.maps.Marker({
    position: myLatlng,
    animation: google.maps.Animation.DROP,
    map,
    title: 'FarBlak',
  });
}

google.maps.event.addDomListener(window, 'load', initialize);
